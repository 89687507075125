// src/router.js
import Vue from 'vue';
import VueRouter from 'vue-router';

import PortalLayout from '../layout/PortalLayout.vue'
import DashboardLayout from '../layout/DashboardLayout.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: PortalLayout,
    redirect: 'home',
    children: [
      {
        path: '/',
        component: () => import('../views/portal/Home.vue')
      }
    ]
  },
  {
    path: '/registration',
    component: PortalLayout,
    children: [
      {
        path: '/',
        component: () => import('../views/portal/Registration.vue')
      }
    ]
  },
  {
    path: '/merchant',
    component: DashboardLayout,
    redirect: 'merchant/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('../views/dashboard/Dashboard.vue'),
        meta: { requiresAuth:true }
      },
      {
        path: 'add-parcel',
        component: () => import('../views/dashboard/AddParcel.vue')
      },
      {
        path: 'entry-parcel-list',
        component: () => import('../views/dashboard/EntryParcelList.vue')
      },
      {
        path: 'under-review-parcel-list',
        component: () => import('../views/dashboard/UnderReviewParcel.vue')
      },
      {
        name: 'total_parcel_list',
        path: 'total-parcel-list/:id?',
        component: () => import('../views/dashboard/TotalParcelList.vue')
      },
      {
        path: 'pickup-request-list',
        component: () => import('../views/dashboard/PickupRequestList.vue')
      },
      {
        path: 'price-changed-list',
        component: () => import('../views/dashboard/PriceChangedList.vue')
      },
      {
        path: 'payment-request-list',
        component: () => import('../views/dashboard/PaymentRequestList.vue')
      },
      {
        name: 'payment_received_list',
        path: 'payment-received-list/:id?',
        component: () => import('../views/dashboard/PaymentReceivedList.vue')
      },
      {
        name: 'adjustment',
        path: 'adjustment',
        component: () => import('../views/dashboard/Adjustment.vue')
      },
      {
        name: 'paid_parcel',
        path: 'paid-parcel',
        component: () => import('../views/dashboard/PaidParcel.vue')
      },
      {
        path: 'return-list',
        component: () => import('../views/dashboard/ReturnList.vue')
      },
      {
        name: 'return_parcel',
        path: 'return-parcel',
        component: () => import('../views/dashboard/ReturnParcel.vue')
      },
      {
        path: 'covarage-area',
        component: () => import('../views/dashboard/CovarageArea.vue')
      },
      {
        path: 'parcel-statistics',
        component: () => import('../views/dashboard/ParcelStatistics.vue')
      },
      {
        path: 'track-parcel',
        component: () => import('../views/dashboard/TrackParcel.vue')
      },
      {
        path: 'pricing-calculator',
        component: () => import('../views/dashboard/PricingCalculator.vue')
      },
      {
        path: 'balance-sheet',
        component: () => import('../views/dashboard/Receivable.vue')
      },
      {
        name: 'complain',
        path: 'complain/:id?',
        component: () => import('../views/dashboard/Complain.vue')
      },
      {
        path: 'fraud-check',
        component: () => import('../views/dashboard/FraudCheck.vue')
      },
      {
        path: 'profile',
        component: () => import('../views/dashboard/Profile.vue')
      },
      {
        path: 'api',
        component: () => import('../views/dashboard/Api.vue')
      }
    ]
  },
];

const router = new VueRouter({
  mode: 'history',
//   base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  routes,
});

router.beforeEach((to, from, next) => {  
  if (to.matched.some(record => record.meta.requiresAuth)) {
      const token = localStorage.getItem('merchantAccessToken');
      if (!token) {
          next({
              path: '/',
              query: { redirect: to.fullPath }
          })
      } else {
          next();
      }
  } else {
    next();
  }
})

export default router;
